<template></template>

<script>
  import { gaLogger } from '@/services/firebase';

  export default {
    data() {
      return {};
    },
    created() {
      if (this.getPWADisplayMode() == 'standalone') {
        console.log('[pwa_loaded] event to emit');
        gaLogger('pwa_loaded');
      }
    },
    methods: {
      getPWADisplayMode() {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
          return 'twa';
        } else if (navigator.standalone || isStandalone) {
          return 'standalone';
        }
        return 'browser';
      },
    },
  };
</script>
