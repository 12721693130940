import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// SENTRY
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import 'bootstrap/dist/css/bootstrap.min.css';

// font awesome
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// library.add(faEye, faEyeSlash);
// "@fortawesome/fontawesome-svg-core": "^1.2.36",
// "@fortawesome/free-solid-svg-icons": "^5.15.4",
// "@fortawesome/vue-fontawesome": "^2.0.2",
// // app.component("font-awesome-icon", FontAwesomeIcon);

import { auth, onAuthStateChanged, gaLogger } from '@/services/firebase';

import './registerServiceWorker';

router.afterEach((to, from) => {
  // console.log(from, to);
  gaLogger('page_view', { to: `${to.fullPath}`, from: `${from.fullPath}` });
});

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

const debug = process.env.VUE_APP_DEBUG_MODE == 'true';

let app;
onAuthStateChanged(auth, async (user) => {
  // CORE LOGIC (order is important)
  // do not create app, if we dont know auth/unauth state
  // if authed, await the token as well, since it is needed in some views (like profile)
  // then make app. if this is a subsequent auth state change, ignore this step
  // treat certain routes with redirects, given auth state

  if (user) {
    await user
      .getIdToken(true)
      .then((idToken) => {
        // Send token to your backend via HTTPS
        store.commit('setToken', idToken);
      })
      .catch((error) => {
        console.error(error);
      });

    await user
      .getIdTokenResult()
      .then((idTokenResult) => {
        // set user roles
        if (idTokenResult.claims.roles != undefined) {
          store.commit('setUserRoles', idTokenResult.claims.roles);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  store.commit('setUser', user);

  if (!app) {
    app = createApp(App);

    // sentry
    Sentry.init({
      app,
      release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}@${process.env.VUE_APP_GIT_COMMIT}`,
      dsn: `${process.env.VUE_APP_SENTRY_DSN}`,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['*'],
        }),
      ],
      ignoreErrors: [],
      tracesSampleRate: 0.5,
      logErrors: true,
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],
    });

    app.use(store).use(router).mount('#app');
  }

  if (debug) {
    console.log(
      'AUTH_STATE CHANGED:',
      'User=',
      store.state.user,
      'Token=',
      store.state.token,
      'EmailVerified=',
      store.state.email_verified
    );
  }
});
