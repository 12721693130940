<template>
  <header>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top" style="background-color: #77c778">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">
          {{ project_name }}
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link class="nav-item nav-link active" to="/projects/new">Δημιουργία Αιτήματος</router-link>
            </li>
            <li class="nav-item">
              <!-- <router-link class="nav-item nav-link" to="/projects">Εκκρεμή Αιτήματα</router-link> -->
              <a class="nav-link active" href="/projects">Εκκρεμή Αιτήματα</a>
            </li>
            <!-- <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
            </li>-->

            <div class="dropdown">
              <!-- <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">FAQ</button> -->
              <a
                class="nav-link dropdown-toggle active"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Συχνές Ερωτήσεις
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <router-link class="dropdown-item" to="/how-it-works">Πως λειτουργεί</router-link>
                </li>
                <li>
                  <hr class="dropdown-divider" v-if="this.$store.state.user" />
                </li>
                <li>
                  <router-link class="dropdown-item" to="/terms-of-use">Όροι Χρήσης</router-link>
                </li>
              </ul>
            </div>
          </ul>
          <!-- <router-link class="nav-item nav-link" to="/signin" v-if="!this.$store.state.user">Sign in</router-link>
        <router-link class="nav-item nav-link" to="/signup" v-if="!this.$store.state.user">Sign up</router-link>
        <router-link class="nav-item nav-link" to="#" v-if="this.$store.state.user" event @click.prevent="redirect_to_account">Account</router-link>
          <router-link class="nav-item nav-link" to="#" v-if="this.$store.state.user" event @click.prevent="logout">Logout</router-link>-->

          <form class="d-flex">
            <!-- <ul class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                <router-link class="nav-item nav-link active" to="/projects/new">Δημιουργία Αιτήματος</router-link>
              </li>
            </ul> -->
            <!-- <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" /> -->
            <!-- <button class="btn btn-outline-success" type="submit">Search</button> -->

            <!-- <ul>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a class="dropdown-item" href="#">Action</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Another action</a>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </li>
            </ul>-->

            <PWAInstallationButton />
            &nbsp;

            <div class="btn-group" v-if="this.$store.state.user">
              <button
                type="button"
                class="btn btn-success dropdown-toggle"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false">
                <i class="fas fa-user-circle"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-lg-end">
                <li>
                  <a class="dropdown-item" href="#" @click.prevent="redirect_to_personal" v-if="this.$store.state.user">
                    Αρχική
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="redirect_to_personal_projects"
                    v-if="this.$store.state.user">
                    Τα Αιτήματα μου
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="redirect_to_personal_offers"
                    v-if="this.$store.state.user">
                    Οι Προσφορές μου
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="redirect_to_personal_account"
                    v-if="this.$store.state.user">
                    Στοιχεία Λογαριασμού
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="redirect_to_personal_payments"
                    v-if="this.$store.state.user">
                    Στοιχεία Πληρωμών
                  </a>
                </li>
                <li>
                  <hr class="dropdown-divider" v-if="this.$store.state.user" />
                </li>
                <li>
                  <a class="dropdown-item" href="#" @click.prevent="sign_out" v-if="this.$store.state.user">
                    Αποσύνδεση
                  </a>
                </li>
              </ul>
            </div>
            <!-- <div class="dropdown" v-if="this.$store.state.user">
              <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-user-circle"></i>
                <i class="fas fa-user"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item" href="#" @click.prevent="redirect_to_account" v-if="this.$store.state.user">Λογαριασμός</a>
                </li>
                <li>
                  <hr class="dropdown-divider" v-if="this.$store.state.user" />
                </li>
                <li>
                  <a class="dropdown-item" href="#" @click.prevent="sign_out" v-if="this.$store.state.user">Αποσύνδεση</a>
                </li>
              </ul>
            </div>-->
            <!-- <button class="btn btn-success" type="submit" v-if="this.$store.state.user" @click.prevent="sign_out">Αποσύνδεση</button> -->
            <button class="btn btn-success" type="submit" v-if="!this.$store.state.user" @click.prevent="sign_in">
              Είσοδος
            </button>
          </form>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
  import { auth, signOut } from '@/services/firebase';
  import PWAInstallationButton from '@/components/PWAInstallationButton.vue';

  // needed for collapse navbar
  import 'bootstrap';

  export default {
    name: 'navigation',
    props: ['project_name'],

    components: {
      PWAInstallationButton,
    },
    methods: {
      sign_out: function () {
        var self = this;
        signOut(auth).then(() => {
          self.$router.push('/signin');
        });
      },
      sign_in: function () {
        var self = this;
        self.$router.push('/signin');
      },
      redirect_to_personal: function () {
        var self = this;
        self.$router.push('/personal');
      },
      redirect_to_personal_projects: function () {
        var self = this;
        self.$router.push('/personal/projects');
      },
      redirect_to_personal_offers: function () {
        var self = this;
        self.$router.push('/personal/offers');
      },
      redirect_to_personal_account: function () {
        var self = this;
        self.$router.push('/personal/account-info');
      },
      redirect_to_personal_payments: function () {
        var self = this;
        self.$router.push('/personal/payments-info');
      },
    },
  };
</script>
