<template>
  <span v-if="shown">
    <button type="button" class="btn btn-success" @click="installPWA">
      <div v-if="install_in_progress" class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      &nbsp;
      <i class="fas fa-download"></i>
      Εγκατάσταση / Install
    </button>
  </span>
</template>

<script>
  // [beforeinstallprompt] every time the app is ready to become pwa
  // [DISPLAY_MODE] 1:1 [page_view] (only in "created" lifecycle)
  // [appinstalled] 1:1 [DISPLAY_MODE_CHANGED]
  // [pwa_installed] instaled from our PWAPrompt (instead of the browser install prompt)
  // the diff #appinstalled - #pwa_installed is the number of times users installed the app withoutu our prompt (say browser install prompt in omnibox)

  import { gaLogger } from '@/services/firebase';

  export default {
    data: () => ({
      shown: false,
      install_in_progress: false,
    }),

    beforeMount() {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.installEvent = e;

        if (this.isMobile()) {
          this.shown = true;
        } else {
          console.log('Device is not mobile, skipping PWA prompt.');
        }

        console.log(`'beforeinstallprompt' event was fired.`);
        gaLogger('beforeinstallprompt');
      });

      window.addEventListener('appinstalled', (e) => {
        // Hide the app-provided install promotion
        this.shown = false;
        // Clear the deferredPrompt so it can be garbage collected
        this.installEvent = null;
      });
    },
    methods: {
      isMobile() {
        // from here https://blog.devgenius.io/4-ways-to-detect-mobile-browsers-in-javascript-943b66657524
        return window.matchMedia('(any-pointer: coarse)').matches;
      },

      installPWA() {
        this.install_in_progress = true;
        this.installEvent.prompt();
        this.installEvent.userChoice.then((choice) => {
          console.log('choice was:', choice);
          if (choice.outcome === 'accepted') {
            // Do something additional if the user chose to install
            console.log('User accepted the pwa prompt and installed the app.');
            gaLogger('pwa_installed');
          } else {
            // Do something additional if the user declined
            console.log('User rejected the pwa prompt.');
            gaLogger('pwa_installation_dismissed');
          }
          this.shown = false;
          this.installEvent = null;
          this.install_in_progress = false;
        });
      },
    },
  };
</script>
