<template></template>

<script>
  // [beforeinstallprompt] every time the app is ready to become pwa
  // [DISPLAY_MODE] 1:1 [page_view] (only in "created" lifecycle)
  // [appinstalled] 1:1 [DISPLAY_MODE_CHANGED]
  // [pwa_installed] instaled from our PWAPrompt (instead of the browser install prompt)
  // the diff #appinstalled - #pwa_installed is the number of times users installed the app withoutu our prompt (say browser install prompt in omnibox)

  import { gaLogger } from '@/services/firebase';

  const MAIN_ENDPOINT = process.env.VUE_APP_MAIN_ENDPOINT;

  export default {
    data() {
      return {};
    },
    beforeMount() {
      window.addEventListener('appinstalled', (e) => {
        // Optionally, send analytics event to indicate successful install
        console.log('PWA was installed', e);
        gaLogger('appinstalled');
        this.debug_notify_pwa_installation();
      });

      window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
        let displayMode = 'browser';
        if (evt.matches) {
          displayMode = 'standalone';
        }
        // Log display mode change to analytics
        console.log('pwa_display_mode_changed', displayMode);
        gaLogger('pwa_display_mode_changed', { value: displayMode });
      });
    },
    methods: {
      debug_notify_pwa_installation: async function () {
        var self = this;

        await self.$store.dispatch('getValidToken');
        fetch(`${MAIN_ENDPOINT}/debug/pwa-installation`, {
          method: 'POST',
          headers: {
            'Authorization': self.$store.state.token,
          },
          body: JSON.stringify({
            userAgent: navigator.userAgent,
          }),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            // console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              // self.pwa_notify_in_progress = false;
              // self.pwa_notify_status = 'Request Submitted!';
            }
          })
          .catch(function (error) {
            console.log(error);
            // self.pwa_notify_in_progress = false;
            // self.pwa_notify_status = appAlertBuilder(error, 'danger');
          });
      },
    },
  };
</script>
