import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import Home from '@/views/Home.vue';

const debug = process.env.VUE_APP_DEBUG_MODE == 'true';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER HOME:', store.state.user);
      }
      if (store.state.user != null) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next();
      }
    },
  },
  {
    path: '/signup',
    name: 'UserSignUp',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/UserSignUp.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER SignUP:', store.state.user);
      }
      if (store.state.user != null) {
        next('/account');
      } else {
        next();
      }
    },
  },

  {
    path: '/signin',
    name: 'UserSignIn',
    component: () => import(/* webpackChunkName: "signin" */ '@/views/UserSignIn.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER SignIN:', store.state.user);
      }
      if (store.state.user != null) {
        next('/account');
      } else {
        next();
      }
    },
  },

  {
    path: '/manage-account',
    name: 'UserManageAccount',
    component: () => import(/* webpackChunkName: "manage_account" */ '@/views/UserManageAccount.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ManageAccount:', store.state.user);
      }
      next();
    },
  },

  {
    path: '/verify-email',
    name: 'UserVerifyEmail',
    component: () => import(/* webpackChunkName: "verify_email" */ '@/views/UserVerifyEmail.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER VerifyEmail:', store.state.user);
      }
      next();
    },
  },

  {
    path: '/reset-pwd',
    name: 'UserResetPassword',
    component: () => import(/* webpackChunkName: "reset_pwd" */ '@/views/UserResetPassword.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ResetPassword:', store.state.user);
      }
      next();
    },
  },

  {
    path: '/change-pwd',
    name: 'UserChangePassword',
    component: () => import(/* webpackChunkName: "change_pwd" */ '@/views/UserChangePassword.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ChangePassword:', store.state.user);
      }
      next();
    },
  },

  {
    path: '/delete-account',
    name: 'UserDeleteAccount',
    component: () => import(/* webpackChunkName: "delete_account" */ '@/views/UserDeleteAccount.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER DeleteAccount:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/projects',
    name: 'ProjectsList',
    component: () => import(/* webpackChunkName: "projects_list" */ '@/views/ProjectsList.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ProjectsList:', store.state.user);
      }
      next();
    },
  },

  {
    path: '/projects/new',
    name: 'ProjectAdd',
    component: () => import(/* webpackChunkName: "project_add" */ '@/views/ProjectAdd.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ProjectAdd:', store.state.user);
      }
      next();

      // OPEN TO ANYONE TO ATTRACT VISITORS
      // if (store.state.user == null) {
      //   console.log('NOT Authed, redirecting..');
      //   next('/signin');
      // } else {
      //   next();
      // }
    },
  },

  {
    path: '/projects/:id',
    name: 'ProjectView',
    component: () => import(/* webpackChunkName: "project_view" */ '@/views/ProjectView.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ProjectView:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/projects/:id/payment',
    name: 'ProjectPayment',
    component: () => import(/* webpackChunkName: "project_payment" */ '@/views/ProjectPayment.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ProjectPayment:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/projects/:id/uploader',
    name: 'ProjectUploader',
    component: () => import(/* webpackChunkName: "project_uploader" */ '@/views/ProjectUploader.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ProjectUploader:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/payment_success',
    name: 'ProjectPaymentSuccess',
    component: () => import(/* webpackChunkName: "project_payment_success" */ '@/views/ProjectPaymentSuccess.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ProjectPaymentSuccess:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },
  {
    path: '/payment_failure',
    name: 'ProjectPaymentFailure',
    component: () => import(/* webpackChunkName: "project_payment_failure" */ '@/views/ProjectPaymentFailure.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER ProjectPaymentFailure:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import(/* webpackChunkName: "terms_of_use" */ '@/views/TermsOfUse.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER TermsOfUse:', store.state.user);
      }
      next();
    },
  },

  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: () => import(/* webpackChunkName: "how_it_works" */ '@/views/HowItWorks.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER HowItWorks:', store.state.user);
      }
      next();
    },
  },

  {
    path: '/personal',
    name: 'Personal',
    component: () => import(/* webpackChunkName: "personal" */ '@/views/Personal.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER Personal:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/personal/account-info',
    name: 'PersonalAccountInfo',
    component: () => import(/* webpackChunkName: "personal_account_info" */ '@/views/PersonalAccountInfo.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER PersonalAccountInfo:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/personal/payments-info',
    name: 'PersonalPaymentsInfo',
    component: () => import(/* webpackChunkName: "personal_payments_info" */ '@/views/PersonalPaymentsInfo.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER PersonalPaymentsInfo:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/personal/projects',
    name: 'PersonalProjectsList',
    component: () => import(/* webpackChunkName: "personal_projects_list" */ '@/views/PersonalProjectsList.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER PersonalProjectsList:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/personal/projects/:id',
    name: 'PersonalProjectManage',
    component: () => import(/* webpackChunkName: "personal_project_manage" */ '@/views/PersonalProjectManage.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER PersonalProjectManage:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/personal/offers',
    name: 'PersonalOffersList',
    component: () => import(/* webpackChunkName: "personal_offers_list" */ '@/views/PersonalOffersList.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER PersonalOffersList:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        next();
      }
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "page_not_found" */ '@/views/PageNotFound.vue'),
    beforeEnter: (to, from, next) => {
      if (debug) {
        console.log('BEFORE ENTER PageNotFound:', store.state.user);
      }
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from) => {
//   console.log(to, from);
//   // ...
//   // explicitly return false to cancel the navigation
//   // return false;
// });

export default router;
