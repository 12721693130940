<template>
  <div id="app">
    <Navigation :project_name="project_name" />
    <ServiceWorkerForceUpdate />
    <PWAInstallationListener />
    <PWATracker />
    <main role="main" class="flex-shrink-0">
      <router-view :project_name="project_name" />
    </main>
    <Footer />
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation.vue';
  import Footer from '@/components/Footer.vue';
  import ServiceWorkerForceUpdate from '@/components/ServiceWorkerForceUpdate.vue';
  import PWAInstallationListener from '@/components/PWAInstallationListener.vue';
  import PWATracker from '@/components/PWATracker.vue';

  export default {
    components: {
      Navigation,
      Footer,
      ServiceWorkerForceUpdate,
      PWAInstallationListener,
      PWATracker,
    },
    data() {
      return {
        project_name: 'Φυτουκλάκι',
      };
    },
    created() {
      let app_name = process.env.VUE_APP_NAME;
      let app_version = process.env.VUE_APP_VERSION;
      let app_git_commit = process.env.VUE_APP_GIT_COMMIT;
      console.log(app_name + '@' + app_version + '@' + app_git_commit);
    },
    methods: {},
  };
</script>

<style>
  html {
    position: relative;
    min-height: 100%;
  }
  /* this is for fixed- navbar  and fixed footer */
  main > .container {
    padding: 60px 0 80px 0;
  }
</style>
