<template>
  <div class="container">
    <div class="bg-light p-5 rounded-lg mt-3 mb-3 m-1">
      <h1 class="display-4">To Φυτουκλάκι</h1>
      <p class="lead">
        Η μεγαλύτερη κοινότητα από...
        <i>φυτουκλάκια</i>
        σε περιμένει! Ό,τι και αν ψάχνεις, όλο και κάποιο
        <i>φυτουκλάκι</i>
        θα έχει αυτό που θέλεις!
      </p>
    </div>

    <div class="row row-cols-1 row-cols-md-2 g-4 m-1">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Θέλω / Ψάχνω να βρω...</h5>
            <p class="card-text">
              Θέλεις να βρεις παλαια θέματα και σημειώσεις από τη σχολή σου για κάποιο μάθημα? Ψάχνεις τα θέματα της
              προηγουμενης εξεταστικής? Θέλεις ιδιαίτερα μαθήματα σε κάποιο γνωστικό αντικείμενο? Στήριξη σε μια
              εργασία/project? Ζήτα αυτό που θέλεις, στην τιμή που ΕΣΥ θέλεις, και βρες ένα
              <i>φυτουκλάκι</i>
              για να συνεργαστείτε!
            </p>
            <button class="btn btn-success stretched-link" type="submit" @click.prevent="redirect_to_add">
              Πες μας τι ψάχνεις
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              Είμαι
              <i>φυτουκλάκι</i>
              και παρέχω..
            </h5>
            <p class="card-text">
              Είμαι
              <i>φυτουκλάκι</i>
              και μπορώ να προσφέρω υπηρεσίες για ιδιαίτερα μαθήματα, υποστήριξη εργασιών, υλικό και παλαιά θέματα
              εξεταστικής και προόδων, σημειώσεις από παραδόσεις μαθημάτων της σχολής και οτιδήποτε άλλο χρειαστεί,
              επιλέγοντας ΕΓΩ με τι θα ασχοληθώ και πόσο θέλω να πληρωθώ, κάνοντας προσφορές στα αιτήματα που θέλω.
            </p>
            <button class="btn btn-success stretched-link" type="submit" @click.prevent="redirect_to_list">
              Δες ποιοί ψάχνουν τι
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      redirect_to_add: function () {
        var self = this;
        self.$router.push('/projects/new');
      },
      redirect_to_list: function () {
        var self = this;
        self.$router.push('/projects');
      },
    },
  };
</script>

<style>
  /* this is for fixed- navbar */
  /* body {
  min-height: 75rem;
  padding-top: 4.5rem;
  margin-bottom: 60px;
} */
  /* html {
  position: relative;
  min-height: 100%;
} */
  /* .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
} */
</style>
